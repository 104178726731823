.ico {
    margin-right: 0.25rem;
}

.textDiv {
    display: inline-block;
}

@media only screen and (max-width: 750px) {
    .textDiv {
        display: none;
    }

    .ico {
        margin: 0;
    }
}